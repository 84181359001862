@keyframes rotate {
	from {
		transform: rotate(-1120deg);
	}
	to {
		transform: rotate(1140deg);
	}
}

.drop-in {
	animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
	animation: drop-in 1200ms ease 500ms backwards;
}

.drop-in-3 {
	animation: drop-in 2000ms ease 700ms backwards;
}

.drop-in-4 {
	animation: drop-in 2500ms ease 500ms backwards;
}

.opacity-in-1 {
	animation: opacity-in 0.5s ease 200ms backwards;
}

.opacity-out-1 {
	animation: opacity-out 1s ease 200ms backwards;
}

@keyframes drop-in {
	from {
		opacity: 0;
		transform: translateY(-100px);
	}
	to {
		opacity: 1;
		transform: translate(0px);
	}
}

@keyframes drop-out {
	from {
		opacity: 1;
		transform: translateY(0px);
	}
	to {
		opacity: 0;
		transform: translateY(-100px);
	}
}

@keyframes opacity-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes opacity-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	70% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
